exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

